import Trix from "trix"

Trix.config.toolbar.getDefaultHTML = () => {
    return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${I18n.t('js.trix.bold')}" tabindex="-1">${I18n.t('js.trix.bold')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${I18n.t('js.trix.italic')}" tabindex="-1">${I18n.t('js.trix.italic')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${I18n.t('js.trix.strike')}" tabindex="-1">${I18n.t('js.trix.strike')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${I18n.t('js.trix.link')}" tabindex="-1">${I18n.t('js.trix.link')}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${I18n.t('js.trix.heading1')}" tabindex="-1">${I18n.t('js.trix.heading1')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${I18n.t('js.trix.quote')}" tabindex="-1">${I18n.t('js.trix.quote')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${I18n.t('js.trix.code')}" tabindex="-1">${I18n.t('js.trix.code')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${I18n.t('js.trix.bullets')}" tabindex="-1">${I18n.t('js.trix.bullets')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${I18n.t('js.trix.numbers')}" tabindex="-1">${I18n.t('js.trix.numbers')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${I18n.t('js.trix.outdent')}" tabindex="-1">${I18n.t('js.trix.outdent')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${I18n.t('js.trix.indent')}" tabindex="-1">${I18n.t('js.trix.indent')}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${I18n.t('js.trix.undo')}" tabindex="-1">${I18n.t('js.trix.undo')}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${I18n.t('js.trix.redo')}" tabindex="-1">${I18n.t('js.trix.redo')}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${I18n.t('js.trix.urlPlaceholder')}" aria-label="${I18n.t('js.trix.url')}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="${I18n.t('js.trix.link')}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="${I18n.t('js.trix.unlink')}" data-trix-method="removeAttribute">
          </div>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new">
            ${I18n.t('js.trix.would_you_like_to_embed_media_from_this_site')}
            <div class="trix-button-group">
              <input class="btn btn-sm" type="button" data-behavior="embed_url" value="${I18n.t('js.trix.yes_embed_it')}">
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}

document.addEventListener('trix-initialize', function(e) {
    let editor  = e.target;
    let toolbar = editor.toolbarElement;
    let ttools  = toolbar.querySelector(".trix-button-group--text-tools");
    let dialogs = toolbar.querySelector(".trix-dialogs");
    let trixId  = editor.trixId;

    let buttonContent = `
    <button type="button"
      class="trix-button trix-button--icon trix-button--icon-attach"
      data-trix-attribute="attach"
      data-trix-key="+" title="${I18n.t('js.trix.attachFile')}" tabindex="-1">
    </button>
  `;

    let dialogContent = `
    <div class="trix-dialog trix-dialog--attach" data-trix-dialog="attach" data-trix-dialog-attribute="attach">
      <div class="trix-dialog__attach-fields">
        <input type="file" class="trix-input trix-input--dialog">
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog"
            onclick="
              var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
              var fileElm = this.parentElement.parentElement.querySelector('input[type=\\'file\\']');
              if ( fileElm.files.length == 0 ) {
                console.log('nothing selected');
                return;
              }
              var file = fileElm.files[0];
              trix.editor.insertFile(file);
            "
            value="${I18n.t('js.trix.attach')}" data-trix-method="removeAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="${I18n.t('js.trix.cancel')}" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  `;
    // add attach icon button
    ttools.insertAdjacentHTML("beforeend", buttonContent);
    // add dialog
    dialogs.insertAdjacentHTML("beforeend", dialogContent);
});

var attributes;

Trix.config.blockAttributes = attributes = {
    "default": {
        tagName: "div",
        parse: false
    },
    quote: {
        tagName: "blockquote",
        nestable: true
    },
    heading1: {
        tagName: "h2",
        terminal: true,
        breakOnReturn: true,
        group: false
    },
    code: {
        tagName: "pre",
        terminal: true,
        text: {
            plaintext: true
        }
    },
    bulletList: {
        tagName: "ul",
        parse: false
    },
    bullet: {
        tagName: "li",
        listAttribute: "bulletList",
        group: false,
        nestable: true,
        test: function(element) {
            return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
        }
    },
    numberList: {
        tagName: "ol",
        parse: false
    },
    number: {
        tagName: "li",
        listAttribute: "numberList",
        group: false,
        nestable: true,
        test: function(element) {
            return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
        }
    },
    attachmentGallery: {
        tagName: "div",
        exclusive: true,
        terminal: true,
        parse: false,
        group: false
    }
};
